import shuffle from "lodash/shuffle";
import uniqueId from "lodash/uniqueId";
import { createLogic, Logic } from "redux-logic";

import exampleFiles from "../images/exampleFiles.json";
import folderList from "../images/examples/folders.json";
import { appendImages, generatePdfComplete, removeAll } from "./actions";
import { fileToDataUrl, generatePdf, getImageRatio, sleep } from "./lib";
import {
	CardImage,
	GENERATE_PDF,
	GeneratePdfAction,
	LOAD_EXAMPLES,
	State,
	UPLOAD_IMAGES,
	UploadImagesAction,
} from "./types";

export const uploadImagesLogic = createLogic({
	type: UPLOAD_IMAGES,
	async process({ action }: { action: UploadImagesAction }, dispatch, done) {
		const images: CardImage[] = await Promise.all(
			action.payload.map(async (image) => {
				const base64src = await fileToDataUrl(image);
				return {
					base64src,
					id: uniqueId("image_"),
					ratio: await getImageRatio(base64src),
					title: image.name,
				};
			})
		);

		dispatch(appendImages(images));
		done();
	},
});

export const generatePdfLogic = createLogic({
	type: GENERATE_PDF,
	latest: true,
	validate(
		{ action, getState }: { action: GeneratePdfAction; getState: () => State },
		allow,
		reject
	) {
		const { processing } = getState();
		if (processing) {
			// Allow only single operation at a time
			reject(action);
		} else {
			allow(action);
		}
	},
	async process(
		{ action, getState }: { action: GeneratePdfAction; getState: () => State },
		dispatch,
		done
	) {
		const { settings, images } = getState();

		// Unlock the thread before heavy computations starts
		await sleep(100);

		const pdf = await generatePdf(images, {
			...settings,
			...action.payload,
		}).catch((err: Error) => alert(err.message));

		if (pdf) {
			const blob = pdf.output("blob");
			window.open(URL.createObjectURL(blob));
			const formData = new FormData();
			// @ts-ignore
			formData.append(
				"file",
				blob,
				"EduZabawyDobble-" + new Date().getTime() + ".pdf"
			);

			fetch(`upload.php`, {
				method: "POST",
				body: formData,
			});
		}

		dispatch(generatePdfComplete());
		done();
	},
});
const getBase64FromUrl = async (url: string) => {
	const data = await fetch(url);
	const blob = await data.blob();
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = () => {
			const base64data = reader.result;
			resolve(base64data);
		};
	});
};

export const loadExamplesLogic = createLogic({
	type: LOAD_EXAMPLES,
	latest: true,
	async process(obj, dispatch, done) {
		console.log("loadExamplesLogic", obj);
		//@ts-ignore
		const folderName = obj.action.payload as string;

		dispatch(removeAll());

		const images: CardImage[] = await Promise.all(
			//@ts-ignore
			shuffle(folderList[folderName]).map(async (file) => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				const base64src = (
					await import(`../images/examples/${folderName}/${file}`)
				).default as string;

				return {
					base64src,
					id: uniqueId("image_"),
					ratio: await getImageRatio(base64src),
					title: file,
				};
			})
			// shuffle(exampleFiles).map(async (file) => {
			// 	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			// 	const base64src = (await import(`../images/${file}`)).default as string;
			// 	return {
			// 		base64src,
			// 		id: uniqueId("image_"),
			// 		ratio: await getImageRatio(base64src),
			// 		title: file,
			// 	};
			// })
		);

		dispatch(appendImages(images));
		done();
	},
});

export default [
	uploadImagesLogic,
	generatePdfLogic,
	loadExamplesLogic,
] as Logic[];
